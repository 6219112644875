/**
 * @file microScripts which used in project
 * @author shaltaev
 */
var skeletToggleDisplay = function (currentElement) {
    var elemsCollection = currentElement.parentElement !== null
        ? currentElement.parentElement.children
        : null;
    if (elemsCollection === null) {
        return;
    }
    // tslint:disable-next-line:prefer-for-of
    for (var i = 0; i < elemsCollection.length; i++) {
        var elem = elemsCollection[i];
        if (elem instanceof HTMLElement) {
            if (elem.classList.contains('aside-sidebar__child-wrapper')) {
                if (elem.style.display === 'flex') {
                    elem.style.display = '';
                }
                else {
                    elem.style.display = 'flex';
                }
            }
        }
    }
};
var skeletProductViewTableElementInc = function (currentElement, incVal) {
    //   console.log(currentElement.parentElement.children.length, incVal);
    var parentElCollection = currentElement.parentElement !== null
        ? currentElement.parentElement.children
        : null;
    if (parentElCollection === null) {
        return;
    }
    // tslint:disable-next-line:prefer-for-of
    for (var i = 0; i < parentElCollection.length; i++) {
        //   for ( el of parentElCollection ){
        var el = parentElCollection[i];
        // debugger;
        if (el instanceof HTMLInputElement) {
            if (el.type === 'text') {
                var value = el.value;
                var resValue = void 0;
                if (value !== null) {
                    resValue =
                        parseInt(value, 10) + incVal > 1 ? parseInt(value, 10) + incVal : 1;
                }
                else {
                    resValue = incVal > 1 ? incVal : 1;
                }
                el.setAttribute('value', resValue.toString());
            }
        }
    }
};
var skeletToggleDisplayOnLoadFirstAside = function () {
    var element = document.querySelector('.aside-sidebar__item');
    if (element !== null) {
        if (document.body.offsetWidth >= 992) {
            skeletToggleThisChilds(element);
        }
    }
};
var skeletToggleDisplayOnLoadAllAside = function () {
    var elements = document.querySelectorAll('div.aside-sidebar > .aside-sidebar__item');
    if (elements !== null) {
        // tslint:disable-next-line:prefer-for-of
        for (var i = 0; i < elements.length; i++) {
            var element = elements[i];
            if (element !== null && element instanceof HTMLElement) {
                var mediaQuery = window.matchMedia('(min-width: 992px)');
                if (mediaQuery.matches) {
                    skeletToggleThisChilds(element);
                }
            }
        }
    }
};
window.addEventListener('load', function (event) {
    skeletToggleDisplayOnLoadAllAside();
});
var skeletToggleThisChilds = function (currentElement) {
    var childsEl = currentElement.nextElementSibling;
    var childs;
    if (childsEl instanceof HTMLElement) {
        childs = childsEl;
    }
    else {
        return;
    }
    if (childs.style.display === 'none') {
        childs.style.display = '';
        // currentElement.style.borderBottomLeftRadius = "";
        // currentElement.style.borderBottomRightRadius = "";
    }
    else {
        childs.style.display = 'none';
        // currentElement.style.borderBottomLeftRadius = "8px";
        // currentElement.style.borderBottomRightRadius = "8px";
    }
};
var skeletUnfixDisplay = function () {
    var body = document.querySelector('body');
    var mediaQuery = window.matchMedia('(min-width: 768px)');
    if (body !== null && mediaQuery.matches) {
        var header_1 = document.getElementsByTagName('header')[0];
        // const nav: HTMLElement = document.getElementsByTagName('nav')[0];
        var headerAndNavWrapperElem = document.getElementsByClassName('header-and-navbar-wrapper')[0];
        var headerAndNavWrapper_1;
        if (headerAndNavWrapperElem instanceof HTMLElement) {
            headerAndNavWrapper_1 = headerAndNavWrapperElem;
        }
        window.onscroll = function () {
            if (window.pageYOffset > header_1.offsetHeight - 42) {
                // if (window.pageYOffset > header.offsetHeight + nav.offsetHeight - 42) {
                headerAndNavWrapper_1.classList.add('fix-nav');
            }
            else {
                headerAndNavWrapper_1.classList.remove('fix-nav');
            }
        };
    }
};
var skeletToogleClass = function (targetElement, className) {
    var element = document.querySelector(targetElement);
    if (element !== null) {
        if (element.classList.contains(className)) {
            element.classList.remove(className);
        }
        else {
            element.classList.add(className);
        }
    }
};
skeletUnfixDisplay();
var skeletSmoothGoToAnchor = function (targetAnchor) {
    var targetElement = document.querySelector(targetAnchor);
    if (targetElement) {
        var endPos_1 = window.innerHeight / 2 + targetElement.offsetTop;
        if (endPos_1 > document.body.offsetHeight - window.innerHeight / 2) {
            endPos_1 = document.body.offsetHeight - window.innerHeight / 2;
        }
        var maxStepSize_1 = 12;
        var stepDuration = 5;
        if (Math.abs(window.pageYOffset + window.innerHeight / 2 - endPos_1) >
            maxStepSize_1) {
            var stepCount = Math.abs(window.pageYOffset + window.innerHeight / 2 - endPos_1) /
                maxStepSize_1;
            var moveStep = function (duration) {
                return sleep(duration).then(function () {
                    window.scrollTo(window.pageXOffset, window.pageYOffset + window.innerHeight / 2 < endPos_1
                        ? window.pageYOffset + maxStepSize_1
                        : window.pageYOffset - maxStepSize_1);
                });
            };
            for (var i = 1; i <= stepCount; i++) {
                moveStep(i * stepDuration);
            }
        }
    }
};
var sleep = function (milliseconds) {
    return new Promise(function (resolve) { return setTimeout(resolve, milliseconds); });
};
// export { skeletToggleDisplay, skeletToggleThisChilds, skeletLogoDynamicFontSize };
