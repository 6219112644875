(function () {
    'use strict';

    angular.module('app')
        .controller('mainController', ['$scope', '$element', '$http', 'basketOffers', function($scope, $element, $http, basketOffers) {
            $scope.total_amounts =
            $scope.basket = {
                'offers': {},
                'total_amounts': '0,00 руб.'
            };

            basketOffers.get().success(response => {
                $scope.basket.total_amounts = response.total_amounts;
                $scope.basket.offers = response.offers;
            });

            $scope.$on('total_amounts', (event, response) => {
                $scope.basket.total_amounts = response;
            });
            $scope.$on('offers', (event, response) => {
                $scope.basket.offers = response;
            });
        }]);
})();
