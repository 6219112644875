(function () {
    'use strict';

    angular.module('app')
        .directive('groupTable', function () {
            return function($scope, element, attrs) {
                element.fadeIn();

                removeTableEmptyColumn(element);

                mergeTableRows(element, 8);
                mergeTableRows(element, 7);
                mergeTableRows(element, 6);
                mergeTableRows(element, 5);
                mergeTableRows(element, 4);
                mergeTableRows(element, 3);
                mergeTableRows(element, 2);


                function mergeTableRows(table, index) {
                    var previous, cell;
                    table.find("td:nth-child(" + index + ")").each(function() {
                        var td = $(this);
                        var content = td.text();
                        if (previous == content && content.length > 0) {
                            td.remove();
                            if (cell.attr("rowspan") == undefined) {
                                cell.attr("rowspan", 2);
                            }
                            else {
                                var current = parseInt(cell.attr("rowspan"));
                                cell.attr("rowspan", current + 1);
                            }
                        }
                        else {
                            previous = content;
                            cell = td;
                        }
                    });
                }

                function removeTableEmptyColumn(table) {
                    table.each(function(i, tbl) {
                        var currentTableRows = $(tbl).find('tbody tr').length;
                        $(tbl).find('th').each(function(i) {
                            var remove = 0;
                            var currentTable = $(this).parents('table');

                            var tds = currentTable.find('tr td:nth-child(' + (i + 1) + ')');
                            tds.each(function() { if ($(this).html().length === 0) remove++; });

                            if (remove == currentTableRows) {
                                $(this).hide();
                                tds.hide();
                            }
                        });
                    });
                }
            };
        });
})();
