(function () {
    'use strict';

    angular.module('app')
        .factory('basketOffers', ['$http', function ($http) {
            return {
                get: () => {
                    return $http.post('/basket/total');
                }
            };
        }]);
})();
