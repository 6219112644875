$(function () {
    'use strict';

    var offersTable = $('#offers-table');

    $('.nav-tabs a').click(function (e) {
        $(this).tab('show');
        var top = $('body').scrollTop();
        window.location.hash = this.hash;
        $('html,body').scrollTop(top);
    });

    $(".fancybox").fancybox({
        openEffect: 'fade',
        closeEffect: 'fade',

        helpers: {
            title: null
        }
    });

    $('[data-cover-change]').on('click', function(event) {
        var current = $(this);
        var normal = current.data('coverChange');

        var product = current.closest('.product');
        var cover = product.find('.product-cover').children('img');

        cover.attr('src', normal);

        event.preventDefault();
        return false;
    });

    $('.nav-catalog').find('#cat' + parseInt(window.current_cat)).addClass('active');
    $('.nav-catalog').find('#cat' + parseInt(window.current_cat)).parent('.subcat').addClass('in');
    $('.nav-catalog').find('#cat' + parseInt(window.current_cat)).parent('.subcat').prev('a');
});
