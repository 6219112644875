(function () {
    'use strict';

    angular.module('app')
        .factory('searchProducts', ['$http', '$cacheFactory', function($http, $cacheFactory) {
            return {
                get: (query, successCallback) => {
                    var key = 'search_' + query.q;
                    if($cacheFactory.get(key) == undefined || $cacheFactory.get(key) == ''){
                        $http.post('/product/search', query).then(function(data){
                            $cacheFactory(key).put('result', data.data);
                            successCallback(data.data);
                        });
                    } else {
                        successCallback($cacheFactory.get(key).get('result'));
                    }
                }
            }
        }]);
})();
