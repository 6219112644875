(function () {
    'use strict';

    angular.module('app')
        .controller('offersController', ['$rootScope', '$scope', '$element', '$http', function($rootScope, $scope, $element, $http) {
            $scope.id = {};
            $scope.amount = {};

            $scope.addToBasket = function ($index) {
                let query = {
                    id: $scope.id[$index],
                    amount: $scope.amount[$index]
                };
                $http.post('/basket/add', query)
                    .success(function(response){
                        $rootScope.$broadcast('total_amounts', response.total_amounts);
                    })
                    .error(function(errors){
                        console.log(errors);
                    });
            };

        }]);
})();
