(function () {
    'use strict';

    angular.module('app')
        .controller('basketController', ['$rootScope', '$scope', '$http', function($rootScope, $scope, $http) {
            $scope.id = {};
            $scope.amount = {};

            $scope.legal = false;

            $scope.clearBasket = function() {
                $http.post('/basket/clear')
                    .success(function(response){
                        $rootScope.$broadcast('total_amounts', response.total_amounts);
                        $rootScope.$broadcast('offers', response.offers);
                    })
                    .error(function(errors){
                        console.log(errors);
                    });
            };

            $scope.updateOffer = function (id) {
                let query = {
                    id: id,
                    amount: $scope.amount[id]
                };
                $http.post('/basket/edit', query)
                    .success(function(response){
                        $rootScope.$broadcast('total_amounts', response.total_amounts);
                        $rootScope.$broadcast('offers', response.offers);
                    })
                    .error(function(errors){
                        console.log(errors);
                    });
            };

            $scope.removeOffer = function (id) {
                let query = {
                    id: id
                };
                $http.post('/basket/remove', query)
                    .success(function(response){
                        $rootScope.$broadcast('total_amounts', response.total_amounts);
                        $rootScope.$broadcast('offers', response.offers);
                    })
                    .error(function(errors){
                        console.log(errors);
                    });
            };


            $scope.setLegal = function () {
                $scope.legal = true;
            };

            $scope.setPerson = function () {
                $scope.legal = false;
            };
        }]);
})();
