(function () {
    'use strict';

    angular.module('app')
        .controller('searchController', ['$scope', 'searchProducts', function($scope, searchProducts) {
            $scope.search = {
                'products': []
            };

            $scope.clearSearch = () => {
                $scope.searchProducts = null;
            };

            $scope.$watch('searchProducts', function (val) {
                let query = {
                    'q': val
                };
                if(val != '' && val != undefined && val.length > 3){
                    searchProducts.get(query, function(response){
                        $scope.search.products = response.products;
                    });
                } else {
                    $scope.search.products = [];
                }
            });
        }]);
})();
